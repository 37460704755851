import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import { BaseLayout, Layout } from 'components';
import { defaultImage, ShowMoreIcon } from 'images';
import {
  PrimaryButton,
  Colors,
  device,
  Modal,
  isMobile,
  Container,
  SvgWrapper,
  Section,
  isLabTop,
  isTablet,
} from 'utils';
import ShareProject from './components/ShareProjct';
import './components/project_details.css';
import {
  ProjectDetails,
  ProjectTab,
  // Tabs,
  RelatedProjects,
  Rewards,
  TimeLineTab,
  BannerComponent,
  Hadeth,
} from './components';
import { Spin } from 'antd';
import { ProjectsActions, DonationsActions } from 'redux/actions';
import { Link } from 'react-router-dom';
import { Carousel } from 'antd';
import { Helmet } from 'react-helmet';

const ProjectWrapper = styled.div`
  .content-Wrapper {
    max-width: 1440px;
    margin: auto;
    @media (max-width: 1441px) {
      max-width: 1120px;
      margin: 0px -10px !important;
    }
  }
  .start-project__component {
    padding: 0 20px;
    h1 {
      font-size: 50px;
      margin: 50px auto;
      max-width: 950px;
      font-weight: bold;
      @media ${device.allMobile} {
        font-size: 25px;
        margin: 15px auto;
      }
      @media ${device.tablet} {
        font-size: 35px;
      }
    }
  }
  .show-more-wrapper {
    position: relative;
    .background {
      position: absolute;
      z-index: -1;
      width: 100%;
      top: -50%;
    }
  }
  .show-more-component {
    display: flex;
    align-items: center;
    @media ${device.allMobile} {
      display: block;
    }
    &__content {
      margin-left: 45px;
      text-align: left;
      @media ${device.allMobile} {
        text-align: center;
        margin-left: 0;
      }
      h2 {
        font-weight: bold;
        font-size: 45px;
        margin: 0;
        @media ${device.allMobile} {
          font-size: 25px;
        }
        @media ${device.laptop} {
          font-size: 32px;
        }
        @media ${device.tablet} {
          font-size: 28px;
        }
      }
      p {
        font-size: 30px;
        @media ${device.allMobile} {
          font-size: 16px;
        }
        @media ${device.laptop} {
          font-size: 20px;
        }
        span {
          display: block;
          a {
            text-decoration: underline;
            &:hover {
              font-weight: bold;
            }
          }
        }
      }
    }
  }
`;
const StartProjectWrapper = styled.div`
  margin: 100px auto;
  text-align: center;

  h1 {
    font-size: 40px;
    color: ${Colors.GreenColor};
    font-weight: bold;
    @media ${device.allMobile} {
      font-size: 25px;
    }
    @media ${device.tablet} {
      font-size: 30px;
    }
  }
`;
const contentStyle = {
  height: '160px',
  color: '#fff',
  lineHeight: '160px',
  textAlign: 'center',
  background: '#364d79',
};
export class ProjectPageTest extends Component {
  componentDidMount() {
    const {
      match: {
        params: { url, id },
      },
      location,
      showProject,
      project,
      updateDraft,
    } = this.props;
    const isViewPage = location.pathname.startsWith('/view');

    if (isViewPage) {
      if (!(project && project.id === id)) {
        updateDraft(id, {});
      }
    } else if (url) {
      showProject(url);
      this.setState({ url });
    }
  }
  state = { url: null, rewardsExist: false, showModal: false };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.url && prevState.url !== nextProps.match.params.url) {
      nextProps.showProject(nextProps.match.params.url);
      return {
        url: nextProps.match.params.url,
      };
    }
    return null;
  }

  // componentDidUpdate(prevState, prevProps) {
  //   const isMobileView = window.innerWidth < 768;
  //   if(isMobileView){
  //     window.scrollTo(0, 0);
  //   }
  // }

  // shouldComponentUpdate() {
  //   window.scrollTo(0, 0);
  // }

  static propTypes = {
    showProject: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    project: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    errors: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    updateDraft: PropTypes.func.isRequired,
    updates: PropTypes.array.isRequired,
    loadingDonations: PropTypes.bool.isRequired,
    getProjectDonations: PropTypes.func.isRequired,
    donations: PropTypes.object.isRequired,
    randomProjects: PropTypes.array.isRequired,
  };

  setRewardsExist = (rewardsExist) => {
    this.setState({ rewardsExist });
  };

  handelCloseModal = () => {
    this.setState({ showModal: false });
    document.body.style.overflowY = 'unset';
  };

  handelShowModal = () => {
    this.setState({ showModal: true });
    document.body.style.overflowY = 'hidden';
  };

  render() {
    const { showModal } = this.state;
    const {
      project,
      loading,
      updates,
      loadingDonations,
      getProjectDonations,
      donations,
      randomProjects,
      match: {
        params: { url },
      },
      location,
    } = this.props;

    const isViewPage = location.pathname.startsWith('/view');
    function generateProjectImages() {
      let projectImages = [];
      if (isViewPage) {
        if (project && project.media && Array.isArray(project.media.images)) {
          projectImages =
            project.media.video &&
            (project.media.video.startsWith('https://www.youtube.com') ||
              project.media.video.startsWith('https://youtu'))
              ? [project.media.video, ...project.media.images]
              : [project.media.video, ...project.media.images];
        } else {
          projectImages = [
            defaultImage,
            defaultImage,
            defaultImage,
            defaultImage,
            defaultImage,
            defaultImage,
            defaultImage,
          ];
        }
      } else {
        let imgs = [];

        // if (isMobile()) {
        //   imgs = Array.isArray(project.mobile_array_images) && project.mobile_array_images.length > 0 ? project.mobile_array_images : project.images;
        // } else {
        //   imgs = Array.isArray(project.images) && project.images.length > 0 ? project.images : project.mobile_array_images;
        // }

        imgs =
          Array.isArray(project.images) && project.images.length > 0
            ? project.images
            : [];
        if (imgs && Array.isArray(imgs)) {
          projectImages = [...imgs];
        }
        if (
          project.video &&
          (project.video.startsWith('https://www.youtube.com') ||
            project.video.startsWith('https://youtu'))
        ) {
          projectImages.unshift(project.video);
        }
      }
      return projectImages;
    }
    const projectImages = generateProjectImages();
    console.log(projectImages[0]);
    return (
      <>
        <Helmet>
          <meta property="og:title" content="OG Title" />
          <meta
            name="description"
            content="description of your website/webpage, make sure you use keywords!"
          />
          <meta
            property="og:description"
            content="description of your website/webpage, make sure you use keywords!"
          />
          <meta
            property="og:url"
            content={`https://commonsplace.de/project/${url}`}
          />
          {/* <meta property="og:image" content={projectImages[0]} /> */}

          {/* <meta
            property="og:image"
            content="https://i.ebayimg.com/images/g/M4AAAOSwAWtlXDcK/s-l400.jpg"
          />
          <meta property="og:image:width" content="300" />
          <meta property="og:image:height" content="200" /> */}
        </Helmet>
        <ProjectWrapper>
          <Spin spinning={loading}>
            <Layout container={true}>
              <div>
                <ProjectDetails
                  projectUrl={url}
                  projectId={project.id}
                  loadingDonations={loadingDonations}
                  project={project}
                  getProjectDonations={getProjectDonations}
                  donations={donations}
                  projectStatus={project.status}
                  projectDescription={
                    isViewPage
                      ? project.projectDescription &&
                        project.projectDescription.description
                      : project.description
                  }
                  projectDonations={
                    isViewPage
                      ? 0
                      : project.donations_sum && Number(project.donations_sum)
                  }
                  projectSupporters={
                    isViewPage
                      ? 0
                      : project.donations_count &&
                        Number(project.donations_count)
                  }
                  projectDays={
                    isViewPage
                      ? project.runTime && project.runTime.funding_days
                      : project.days_left && Number(project.days_left)
                  }
                  projectImages={projectImages}
                  projectGoal={project.goal && Number(project.goal)}
                  projectFinalGoal={
                    project.final_goal && Number(project.final_goal)
                  }
                  isViewPage={isViewPage}
                  banner={project && project.banner}
                  title={
                    isViewPage
                      ? project.basicInfo && project.basicInfo.title
                      : project.title
                  }
                  subtitle={
                    isViewPage
                      ? project.basicInfo && project.basicInfo.subtitle
                      : project.subtitle
                  }
                  rewardsExist={this.state.rewardsExist}
                />

                {/* <Tabs /> */}
                {project && (
                  <ProjectTab
                    {...this.props}
                    project={project}
                    isViewPage={isViewPage}
                    updates={updates}
                    loadingDonations={loadingDonations}
                    projectId={project.id}
                    getProjectDonations={getProjectDonations}
                    donations={donations}
                    handelShowModal={this.handelShowModal}
                  />
                )}

                {project.id && (
                  <Rewards
                    projectStatus={project.status}
                    projectId={project.id}
                    setRewardsExist={this.setRewardsExist}
                    isTicketable={project.is_ticketable}
                  />
                )}

                {!isViewPage && updates.length > 0 && (
                  <TimeLineTab updates={[...updates]} key={Date.now()} />
                )}

                {!isViewPage && (
                  <>
                    <RelatedProjects
                      randomProjects={randomProjects}
                      isTicketable={project.is_ticketable}
                    />
                    <Section className="show-more-wrapper">
                      <Container>
                        <div className="show-more-component">
                          <div className="show-more-component__image">
                            <SvgWrapper
                              width={
                                isMobile()
                                  ? '100%'
                                  : isTablet()
                                  ? '270px'
                                  : isLabTop()
                                  ? '370px'
                                  : '410px'
                              }
                              height={
                                isMobile()
                                  ? 'auto'
                                  : isTablet()
                                  ? 'auto'
                                  : isLabTop()
                                  ? '270px'
                                  : '310px'
                              }
                            >
                              <ShowMoreIcon />
                            </SvgWrapper>
                          </div>
                          <div className="show-more-component__content">
                            <h3>Höchste Sicherheitsstandards</h3>
                            <p>
                              Für commonsplace ist das Vertrauen der Community
                              das Wichtigste!{' '}
                              <span>
                                <Link to="/transparency-and-trust">
                                  Mehr lesen
                                </Link>
                              </span>
                            </p>
                          </div>
                        </div>
                      </Container>
                    </Section>
                    <StartProjectWrapper
                      className="start-project__component"
                      style={{ marginTop: 0 }}
                    >
                      <>
                        <h3>
                          Starte dein eigenes Projekt und bereichere die
                          Community!
                        </h3>

                        <Link to="/start-project">
                          <PrimaryButton>Jetzt Projekt starten</PrimaryButton>
                        </Link>
                      </>
                    </StartProjectWrapper>
                    <Hadeth />
                  </>
                )}
              </div>
              <Modal
                showModal={showModal}
                handelCloseModal={this.handelCloseModal}
              >
                <ShareProject
                  subtitle={project.subtitle}
                  title={project.title}
                  banner={project.banner}
                />
              </Modal>
            </Layout>
          </Spin>
        </ProjectWrapper>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { updates, project, loading, errors, randomProjects } = state.projects;
  const {
    loading: loadingDonations,
    errors: errorsDonations,
    donations,
  } = state.donations;

  return {
    project,
    updates,
    loading,
    errors,
    donations,
    loadingDonations,
    errorsDonations,
    randomProjects,
  };
};

const mapDispatchToProps = (dispatch) => ({
  showProject: (url) => dispatch(ProjectsActions.showProject(url)),
  updateDraft: (id, project) =>
    dispatch(ProjectsActions.updateDraft(id, project)),
  getProjectDonations: (projectId, perPage, page) =>
    dispatch(DonationsActions.getProjectDonations(projectId, perPage, page)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectPageTest);
